import NRInformationModal from '../../../NRInformationModal/NRInformationModal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import NRAutocomplete from '../../../NRAutocomplete/NRAutocomplete';
import AssetGroupGrid from './AssetGroupGrid';
import Button from '@mui/material/Button';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { AssetGroupContext } from '../../../AssetGroup/assetGroupContextWrapper';
import useNRMutation from '../../../../graphql/UseNRMutation';
import { QL_SET_GROUPS_TO_ASSET } from '../../../../graphql/queries/assetGroup';
import NRButtonProgress from '../../../NRButtonProgress/NRButtonProgress';
import { CONSTANTS } from '../../../../constants/constants';
import useFetchAssetGroups from '../../../AssetGroup/useFetchAssetGroups';
import { GET_ASSETS } from '../../../../graphql/queries/asset';
import { useTheme } from '@mui/material/styles';
import isEqual from 'lodash.isequal';
import { useLocation } from 'react-router-dom';
import { ComponentContext } from '../../../../ContextWrappers/NRComponentContextWrapper';
import useHasPermission from '../../../Permissions/useHasPermission';
import WithPermissions from '../../../Permissions/WithPermissions';

function AssetGroupProviderModal() {
  const theme = useTheme();
  const {
    showManageGroupsModal,
    setShowManageGroupsModal,
    selectedAsset,
    selectedGroups,
    setSelectedGroups,
    assetGroups,
    assetGroupsLoading,
    assetsRelayArgs,
    disableEdit
  } = useContext(AssetGroupContext);
  const { setRefetch } = useContext(ComponentContext);

  useFetchAssetGroups();
  const location = useLocation();
  const pathname = location.pathname;
  const canWriteGroups = useHasPermission({ permission: CONSTANTS.PERMISSIONS.WRITE_GROUPS });

  const [rowToUse, setRowToUse] = useState(selectedAsset);
  const [setGroupsToAsset, response, loading] = useNRMutation(
    QL_SET_GROUPS_TO_ASSET,
    'setAssetGroupsToAsset',
    CONSTANTS.MESSAGES.GROUPS_UPDATED_SUCCESSFULLY,
    null,
    {
      refetchQueries: [
        pathname?.includes('/assets/') ? null : { query: GET_ASSETS, variables: { args: assetsRelayArgs }, context: { version: 'v3' } }
      ]
    }
  );

  useEffect(() => {
    const rowToSet = { ...selectedAsset, assetGroups: selectedGroups || [] };
    setRowToUse(rowToSet);
  }, [selectedGroups]);

  useEffect(() => {
    if (!!selectedAsset && !!assetGroups) {
      const selectedOnes = assetGroups.filter(e => selectedAsset?.assetGroups?.find(o => o.id === e.id));
      setSelectedGroups(selectedOnes || []);
    }
  }, [assetGroups, selectedAsset]);

  const handleSave = () => {
    setGroupsToAsset({
      variables: { args: { assetId: selectedAsset.id, groupIds: selectedGroups?.map(e => e.id) } },
      context: { version: 'v3' }
    });
  };

  useEffect(() => {
    if (response) {
      if (pathname.includes('/assets/')) {
        setRefetch(true);
      }
      setShowManageGroupsModal(false);
    }
  }, [response]);

  return (
    <NRInformationModal isOpen={showManageGroupsModal} setIsOpen={setShowManageGroupsModal} maxHeight={850}>
      <Box sx={{ minHeight: 500, minWidth: 550, maxHeight: 850 }} px={1} display={'flex'} flexDirection={'column'}>
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
          <Typography variant="h3" component="span">
            Groups
          </Typography>
          <IconButton aria-label="Close" onClick={() => setShowManageGroupsModal(false)} size="large">
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        {!disableEdit && (
          <WithPermissions permission={CONSTANTS.PERMISSIONS.WRITE_GROUPS}>
            <Box>
              <NRAutocomplete
                arrayData={assetGroups}
                value={selectedGroups}
                setValue={setSelectedGroups}
                loading={assetGroupsLoading}
                dataNameSingular={'Group'}
                fieldToShow={'name'}
                idField={'id'}
                showAlwaysTitle={false}
                title={
                  <>
                    <Typography
                      variant={'body1'}
                      sx={{
                        '& > svg': {
                          width: theme.spacing(3),
                          height: theme.spacing(3)
                        },
                        '& > span': {
                          paddingLeft: theme.spacing(1)
                        }
                      }}
                      display={'flex'}
                    >
                      {/*<NRGroupIcon width={'24'} height={'24'} />*/}
                      <span>Add to group(s)</span>
                    </Typography>
                  </>
                }
              />
            </Box>
          </WithPermissions>
        )}
        <Box py={2}>
          <AssetGroupGrid rows={rowToUse?.assetGroups} setSelectedGroups={setSelectedGroups} />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} mt={'auto'} ml={'auto'}>
          <Button variant={'outlined'} color={'secondary'} onClick={() => setShowManageGroupsModal(false)} sx={{ mr: !!canWriteGroups ? 1 : 0 }}>
            Cancel
          </Button>
          {!disableEdit && (
            <WithPermissions permission={CONSTANTS.PERMISSIONS.WRITE_GROUPS}>
              <NRButtonProgress
                variant="contained"
                color="secondary"
                disabled={!!loading || isEqual(selectedGroups?.map(e => e.id)?.sort(), selectedAsset?.assetGroups?.map(e => e.id)?.sort())}
                isLoading={loading}
                type={'button'}
                label={'Save'}
                onClick={handleSave}
              />
            </WithPermissions>
          )}
        </Box>
      </Box>
    </NRInformationModal>
  );
}

export default AssetGroupProviderModal;
